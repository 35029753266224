import React from "react";
const CustomerTransactionList = React.lazy(() => import("./pages/customer/CustomerTransactionList"));
const AdminMailToUsers = React.lazy(() => import("./pages/admin/AdminMailUsers"));
const AdminMailToCustomers = React.lazy(() => import("./pages/admin/AdminMailCustomers"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const SignUpCareer = React.lazy(() => import("./pages/SignUpCareer"));
const SignUpBusiness = React.lazy(() => import("./pages/SignUpBusiness"));
const JoinWithUs = React.lazy(() => import("./pages/JoinWithUs"));
const GuestHome = React.lazy(() => import("./pages/GuestHome"));
const Associates = React.lazy(() => import("./pages/admin/Associates"));
const WishList = React.lazy(() => import("./pages/WishList"));
const ShowQR = React.lazy(() => import("./pages/ShowQR"));
const Profile = React.lazy(() => import("./pages/Profile"));
const AssociateProfile = React.lazy(() => import("./pages/admin/AssociateProfile"));
const Search = React.lazy(() => import("./pages/Search"));
const AdminSearch = React.lazy(() => import("./pages/AdminSearch"));
const Store = React.lazy(() => import("./pages/Store"));
const Home = React.lazy(() => import("./pages/Home"));
const Admin = React.lazy(() => import("./pages/admin/index"));
const AdminBusinessList = React.lazy(() => import("./pages/admin/AdminBusinessList"));
const AdminCustomerList = React.lazy(() => import("./pages/admin/AdminCustomerList"));
const AdminFeedbackList = React.lazy(() => import("./pages/admin/AdminFeedbackList"));
const AdminUserList = React.lazy(() => import("./pages/admin/AdminUserList"));
const AdminTransactionList = React.lazy(() => import("./pages/admin/AdminTransactionList"));
const AdminBusiness = React.lazy(() => import("./pages/admin/AdminBusiness"));
const CustomerBusiness = React.lazy(() => import("./pages/customer/CustomerBusiness"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));
const AssociateHome = React.lazy(() => import("./pages/associate"));
const CustomerHome = React.lazy(() => import("./pages/customer"));
const AssociateTransactionList = React.lazy(() => import("./pages/associate/AssociateTransactionList"));
const AssociateCustomerList = React.lazy(() => import("./pages/associate/AssociateCustomerList"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));

const routes = [
    { path: "/", exact: true, name: "Home", component: Home },
    { path: "/signup", exact: true, name: "Signup", component: SignUp },
    { path: "/signupcareer", exact: true, name: "Signup", component: SignUpCareer },
    { path: "/signupbusiness", exact: true, name: "Signup", component: SignUpBusiness },
    { path: "/resetpassword", exact: true, name: "Signup", component: ResetPassword },


    { path: "/profile", exact: true, name: "Profile", component: Profile },
    { path: "/wishlist", exact: true, name: "Profile", component: WishList },
    { path: "/store/:id", exact: true, name: "Store", component: Store },
    { path: "/search", exact: true, name: "Search", component: Search },
    { path: "/admin/search", exact: true, name: "Admin Search", component: AdminSearch },
    { path: "/admin/transactions", exact: true, name: "Admin Transaction", component: AdminTransactionList },
    { path: "/admin", exact: true, name: "Admin", component: Admin },
    { path: "/admin/customerlist", exact: true, name: "Admin", component: AdminCustomerList },
    { path: "/admin/feedbacks", exact: true, name: "Admin", component: AdminFeedbackList },
    { path: "/admin/userlist", exact: true, name: "Admin", component: AdminUserList },
    { path: "/admin/mailtocustomers", exact: true, name: "Admin", component: AdminMailToCustomers },
    { path: "/admin/mailtousers", exact: true, name: "Admin", component: AdminMailToUsers },
    { path: "/admin/businesslist", exact: true, name: "Admin", component: AdminBusinessList },
    { path: "/admin/business/:id", exact: true, name: "Admin", component: AdminBusiness },
    { path: "/admin/associates", exact: true, name: "Associate List", component: Associates },
    { path: "/admin/associate/:id", exact: true, name: "AssociateProfile", component: AssociateProfile },
    { path: "/showqr", exact: true, name: "Show QR", component: ShowQR },

    { path: "/associate", exact: true, name: "Associate Home", component: AssociateHome },
    { path: "/associate/transactions", exact: true, name: "Admin Transaction", component: AssociateTransactionList },
    { path: "/associate/customerlist", exact: true, name: "Admin Transaction", component: AssociateCustomerList },

    { path: "/customer", exact: true, name: "Customer Home", component: CustomerHome },
    { path: "/customer/business/:id", exact: true, name: "Customer Business", component: CustomerBusiness },
    { path: "/customer/transactions", exact: true, name: "Admin Transaction", component: CustomerTransactionList },


    { path: "/guest", exact: true, name: "Guest Home", component: GuestHome },
    { path: "/joinwithus", exact: true, name: "Join With Us", component: JoinWithUs },

    { path: "*", name: "Not Found", component: NotFoundPage },

];

export default routes;
