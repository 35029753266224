import { useEffect, useState } from "react";
import {
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import { useAtom } from "jotai";
import { locationStorageAtom } from "../services/jotaiService";
import mapboxgl from "mapbox-gl";
import LocationPicker from "./LocationPicker";

interface LocationData {
  placeName: string;
  cityName: string;
  stateName: string;
  countryName: string;
}

interface Props {
  closeModal: () => void;
  toast: any;
  setLocationName: any;
  locationName: any;
}
function LocationSelectComponent({
  closeModal,
  toast,
  locationName,
}: Props): JSX.Element {
  mapboxgl.accessToken =
    "pk.eyJ1IjoiYmFzaXRoMTIxNiIsImEiOiJjbGRtcnlmYzkwYWtyM29xZ2tpZnNqdDhvIn0.GOwAsUj-GqOZEPASQGTcwg";
  const [searchText, setSearchText] = useState<String>("");
  const [locationData, setLocationData] = useState<LocationData>({
    placeName: "",
    cityName: "",
    stateName: "",
    countryName: "",
  });
  const [locationAtom, setLocationAtom] = useAtom(locationStorageAtom);
  const [location, setLocation] = useState<any>({
    latitude: null,
    longitude: null,
  });
  useEffect(() => {
    setLocationData({
      placeName: locationName,
      cityName: "",
      stateName: "",
      countryName: "",
    });
  }, [locationName]);

  useEffect(() => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${location.longitude},${location.latitude}.json?access_token=${mapboxgl.accessToken}`
    )
      .then((response) => response.json())
      .then((data) => {
        // The response will contain an array of features, each representing a possible location
        if (data.features && data.features.length > 0) {
          const placeFeature = data.features.find((feature: any) =>
            feature.place_type.includes("locality")
          );
          const cityFeature = data.features.find((feature: any) =>
            feature.place_type.includes("place")
          );
          const stateFeature = data.features.find((feature: any) =>
            feature.place_type.includes("region")
          );
          const countryFeature = data.features.find((feature: any) =>
            feature.place_type.includes("country")
          );
          setLocationData({
            placeName: placeFeature ? placeFeature?.text : "",
            cityName: cityFeature ? cityFeature?.text : "",
            stateName: stateFeature ? stateFeature?.text : "",
            countryName: countryFeature ? countryFeature?.text : "",
          });
        } else {
          setLocationData({
            placeName: "",
            cityName: "",
            stateName: "",
            countryName: "",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
        setLocationData({
          placeName: "",
          cityName: "",
          stateName: "",
          countryName: "",
        });
      });
  }, [location.latitude, location.longitude]);

  return (
    <div className="mt-4">
      <LocationPicker
        locationPlace={
          locationData.placeName
            ? locationData.placeName
            : locationName
            ? locationName
            : ""
        }
        location={location}
        setLocation={setLocation}
      />

      {!!locationAtom.place_name && (
        <div className="w-full border border-sky-700 mt-3 bg-sky-100 rounded p-2 ">
          <div className="text-sky-600  font-semibold">Selected Location</div>

          <div className="text-sky-700  flex gap-1 items-center font-semibold  text-lg">
            <LocationMarkerIcon className="w-3" /> {locationAtom.place_name}
          </div>
        </div>
      )}

      <div
        onClick={closeModal}
        className="border cursor-pointer border-slate-400 rounded mt-4 flex gap-1 h-14 justify-center 
            items-center text-slate-700 hover:text-slate-900 bg-slate-300 font-semibold text-lg hover:bg-slate-400"
      >
        Submit
      </div>
    </div>
  );
}
export default LocationSelectComponent;
