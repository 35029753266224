import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const userStorageAtom = atomWithStorage<any>("user", {});
export const tokenStorageAtom = atomWithStorage<any>("token", "");
export const locationStorageAtom = atomWithStorage<any>("location", {});
export const productWishListStorageAtom = atomWithStorage<any>(
  "productWishList",
  []
);
export const businessWishListStorageAtom = atomWithStorage<any>(
  "businessWishList",
  []
);
export const locationModalShowAtom = atom<boolean>(false);
