import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FormErrorMsg } from "../components/FormErrorMsg";
import * as yup from "yup";
import { useQuery } from "react-query";
import { LoginUser } from "../services/apiService";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import { useRef, useState } from "react";
import { LoginIcon } from '@heroicons/react/solid'
import { tokenStorageAtom, userStorageAtom } from "../services/jotaiService";
import { Password } from "primereact/password";


interface Props {
  onHide: () => void;
}

function Login(props: Props) {
  const navigate = useNavigate();

  const [, setUserAtom] = useAtom(userStorageAtom);
  const [tokenAtom, setTokenAtom] = useAtom(tokenStorageAtom);
  const [isLogin, setIsLogin] = useState<boolean>(false);


  return (
    <div className="">

      <div className="flex justify-center items-center mt-12 ">
        <Formik
          initialValues={{
            userName: '',
            password: ''
          }}
          validationSchema={
            yup.object({
              userName: yup.string().required("Username required!"),
              password: yup.string().required("Password required!"),
            })
          }
          onSubmit={async (values, { setFieldError }) => {
            setFieldError("userName", "");
            setFieldError("password", "");
            setIsLogin(true);
            const { userName, password } = values;
            const response = await LoginUser(userName, password);
            if (response?.data.status === "true") {
              setIsLogin(false);
              setTokenAtom(`${response?.data?.access_token}`);
              setUserAtom(response?.data?.user);
              if (props.onHide) props.onHide();
              switch (Number(response?.data?.user?.type)) {
                case 0: return navigate('/admin');
                case 1: return navigate('/associate')
                case 2: return navigate('/customer');
                case 3: return navigate('/');
                default: alert(Number(response?.data?.user?.type)); return;
              }

            } else {
              setIsLogin(false);
              setFieldError("password", response?.data?.message || "");
            }
          }}

        >
          {() => (
            <Form className="border block p-6 pt-8   pb-12  w-[400px]  rounded-lg  text-center">
              <div className="flex justify-center ">
                <img src="/assets/icons/fairbiss_icon.svg" alt="main_logo" />
              </div>
              <div className="text-3xl font-semibold mt-5">
                Login
              </div>
              <Field
                placeholder='Username'
                name="userName"
                className="w-full  h-[50px] rounded-xl mt-6 pl-4 border bg-gray-100"
                as={InputText}
              />
              <ErrorMessage
                className="w-full"
                name="userName"
                render={(msg) => <FormErrorMsg msg={msg} />}
              />
              
              <Field
                className="h-[50px] w-full rounded-xl mt-6  border focus:border-none"
                placeholder="Password "
                inputClassName='w-full bg-gray-100 h-[50px] rounded-xl bg-blue-100'
                type="password"
                name="password"
                toggleMask
                feedback={false}
                as={Password}
              />
              <ErrorMessage
                className="w-full "
                name="password"
                render={(msg) => <FormErrorMsg msg={msg} />}
              />
              <div className="text-blue-700 text-sm text-right mt-1">
                <span className="cursor-pointer hover:underline"
                  onClick={() => { props.onHide(); navigate('/resetpassword') }}
                >
                  Forgot your password?
                </span>
              </div>
              <Button label={isLogin ? 'Login...' : 'Login'} type="submit"
                disabled={isLogin}
                loading={isLogin}
                className="bg-[#0f00ff] w-[160px] h-[50px] rounded-xl mt-12 font-bold text-white " />
            </Form>
          )}

        </Formik>

      </div>
      <div className="flex justify-center">
        <div className="text-sm w-[400px] text-right mt-1 ">
          <span className="font-semibold text-gray-500 mr-4">
            Don't have an Account? <span className="text-blue-700 cursor-pointer hover:underline"
              onClick={
                () => { props.onHide(); navigate('/signup') }}
            >Sign Up</span>
          </span>
        </div>
      </div>
    </div >
  );
}

export default Login;
