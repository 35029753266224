

function NotFoundPage() {

  return (
    <div className="h-full min-h-[250px] flex items-end justify-center w-full ">

      <div className="flex text-2xl justify-center text-gray-800">
        <div className="font-bold">404</div>
        <div className="ml-4">Page Not Found</div>

      </div>
    </div>
  );
}

export default NotFoundPage;
