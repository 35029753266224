import React, { useState, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import { useAtom } from "jotai";
import { locationStorageAtom } from "../services/jotaiService";

interface Location {
  latitude: number | null;
  longitude: number | null;
}

interface LocationPickerProps {
  locationPlace: any;
  location: any;
  setLocation: React.Dispatch<React.SetStateAction<Location>>;
}

const LocationPicker: React.FC<LocationPickerProps> = ({
  location,
  locationPlace,
  setLocation,
}) => {
  const [locationAtom, setLocationAtom] = useAtom(locationStorageAtom);
  const [value, setValue] = useState<string>(locationAtom?.place_name || null);
  const [items, setItems] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [enterPressed, setEnterPressed] = useState<boolean>(false);

  const search = (event: any) => {
    setItems(
      Array.from(Array(10).keys()).map((item) => event.query + "-" + item)
    );
  };

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmFzaXRoMTIxNiIsImEiOiJjbGRtcnlmYzkwYWtyM29xZ2tpZnNqdDhvIn0.GOwAsUj-GqOZEPASQGTcwg";
    const getSuggestions = (value: string) => {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${mapboxgl.accessToken}`
      )
        .then((response) => response.json())
        .then((data) => {
          const featureNames = data.features.map(
            (feature: any) => feature.place_name
          );
          setSuggestions([...featureNames.slice(0, 5)]);
        });
    };
    getSuggestions(value);
  }, [value]);

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmFzaXRoMTIxNiIsImEiOiJjbGRtcnlmYzkwYWtyM29xZ2tpZnNqdDhvIn0.GOwAsUj-GqOZEPASQGTcwg";
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [
        location.longitude ? location.longitude : null,
        location.latitude ? location.latitude : null,
      ],
      zoom: 14,
    });
    if (location.longitude && location.latitude) {
      new mapboxgl.Marker()
        .setLngLat([location.longitude, location.latitude])
        .addTo(map);
    }

    map.on("click", (e: any) => {
      const { lat, lng } = e.lngLat;
      setLocation({ latitude: e.lngLat.lat, longitude: e.lngLat.lng });
    });

    return () => {
      map.remove();
    };
  }, [location]);

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };

  useEffect(() => {
    if (locationPlace) {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${locationPlace}.json?access_token=${mapboxgl.accessToken}`
      )
        .then((res) => res.json())
        .then((data) => {
          const [longitude, latitude] = data.features[0].center;
          setLocation({
            latitude: latitude,
            longitude: longitude,
          });
        });
    }
  }, []);

  const handleSuggestionSelected = (e: any) => {
    const selectedSuggestion = e.value;

    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${selectedSuggestion}.json?access_token=${mapboxgl.accessToken}`
    )
      .then((res) => res.json())
      .then((data) => {
        const [longitude, latitude] = data.features[0].center;
        setLocationAtom({
          place_name: selectedSuggestion,
          longitude,
          latitude,
        });
        setLocation({
          latitude: latitude,
          longitude: longitude,
        });
        setValue(selectedSuggestion);
      });
  };

  return (
    <>
      <div className="text-slate-500 font-medium mt-2">Type your location</div>
      <div style={{ position: "relative" }} className="w-full">
        <AutoComplete
          value={value}
          suggestions={suggestions}
          completeMethod={search}
          onChange={(e) => {
            setValue(e.value);
          }}
          onSelect={handleSuggestionSelected}
          className="w-full"
          inputClassName="w-full"
          placeholder="Type your place name..."
        />
      </div>

      <div className="text-slate-500 font-medium mt-3">
        Choose your location from map
      </div>
      <div id="map" className="w-full h-[300px] mt-1 border" />
      <div className="flex  justify-center mt-2">
        <Button
          onClick={() => getUserLocation()}
          icon={<LocationMarkerIcon className="w-4" />}
          label="Use Current Location"
        />
      </div>
    </>
  );
};

export default LocationPicker;
