import axios from "axios";
import { CustomerType, UserType } from "./types";
import { useAtom } from "jotai";

const url = process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";

export const LoginUser = async (email: String, password: String) => {
    try {
        const data: any = await axios.post(`${url}/login`, {
            email,
            password
        });

        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const RegisterUser = async (user: UserType) => {
    try {
        const data: any = await axios.post(`${url}/users_create`, user
        );
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const RegisterCustomer = async (user: CustomerType) => {
    try {
        const data: any = await axios.post(`${url}/customer_create`, user
        );
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const RegisterCustomerByAdmin = async (token: string, user: CustomerType) => {
    try {
        const data: any = await axios.post(`${url}/store_customer_by_admin`, user, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const createShop = async (shop: any, token: any) => {

    try {
        const data: any = await axios.post(`${url}/shops_create`, shop, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const updateShop = async (shopId: any, shop: any, token: any) => {

    try {
        const data: any = await axios.post(`${url}/shop_update/${shopId}`, shop, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const deleteShop = async (shopId: any, token: any) => {

    try {
        const data: any = await axios.delete(`${url}/shop_delete/${shopId}`,  {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const createProduct = async (product: any, token: any) => {

    try {
        const data: any = await axios.post(`${url}/products_create`, product, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const updateProduct = async (id: any, product: any, token: any) => {

    try {
        const data: any = await axios.post(`${url}/product_update/${id}`, product, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        );
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const getCustomers = async (searchText: String, token: any) => {
    try {
        const data: any = await axios.get(`${url}/get_custumer_list?name=${searchText}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getFeedbacks = async (query: String, token: any) => {
    try {
        const data: any = await axios.get(`${url}/get_feedback`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getUsers = async (searchText: String, token: any) => {
    try {
        const data: any = await axios.get(`${url}/get_user_list?name=${searchText}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const searchLocationAPI = async (searchText: String) => {
    try {
        const data: any = await axios.get(`${url}/get_current_locations?name=${searchText}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}
export const getCustomerShopList = async (token: String) => {
    try {
        const data: any = await axios.get(`${url}/get_list_shops_ass`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const activateUser = async (token: String, id: string) => {
    try {
        const data: any = await axios.get(`${url}/change-status-active/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const deActivateUser = async (token: String, id: string) => {
    try {
        const data: any = await axios.get(`${url}/change-status-deactive/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getShopDetailsByUser = async (shopId: any, token: String) => {
    try {
        const data: any = await axios.get(`${url}/shop_details/${shopId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getGuestShopList = async (text: any) => {
    try {
        const data: any = await axios.get(`${url}/get_list_shops_open?search=${text}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getGuestShopListWithLocation = async (text: any) => {
    try {
        const data: any = await axios.get(`${url}/get_current_shops?place_name=${text}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getAdminHomeData = async () => {
    try {
        const data: any = await axios.get(`${url}/get_dashboard_details`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getHomeShopList = async (text: any, query: any) => {
    try {
        const data: any = await axios.get(`${url}/get_list_shops_open?search=${text}${query}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getHomeShopListByLocation = async (location: any, query: any) => {
    try {
        const data: any = await axios.get(`${url}/get_current_shops?place_name=${location}${query}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getHomeShopListByLocationCoordinates = async (latitude:any,longitude:any,page:any) => {
    try {
        const data: any = await axios.get(`${url}/shops/range?latitude=${latitude}&longitude=${longitude}&page=${page}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}


export const getProductListDetailsByCustomerByShop = async (text: any, shopId: any, token: String) => {
    try {
        const data: any = await axios.get(`${url}/get_list_products_ass?shop=${shopId}&search=${text}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getPublicProductListByShop = async (shopId: any, text: any) => {
    try {
        const data: any = await axios.get(`${url}/get_list_products_open?shop=${shopId}&search=${text}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}



export const getPublicProductList = async (text: any) => {
    try {
        const data: any = await axios.get(`${url}/get_list_products_open?search=${text}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getSearchItemList = async  (text: any, query: any)  => {
    try {
        const data: any = await axios.get(`${url}/all_search_list?search=${text}${query}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}



export const addShopToWishList = async (shopId: any, token: any) => {
    try {
        const data: any = await axios.post(`${url}/wish-list-add-shop`, {
            shop_id: shopId
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}


export const resendOTP = async (email: any) => {
    try {
        const data: any = await axios.post(`${url}/recent_user_otp`, {
            email
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const resetPassword = async (data: {
    email: string;
    otp: string,
    password: string;
}) => {
    try {
        const response: any = await axios.post(`${url}/forgot_password`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getShopWishList = async (token: any) => {
    try {
        const data: any = await axios.get(`${url}/wish-list-shop`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}


export const generateForgotPasswordOTP = async (email: string) => {
    try {
        const data: any = await axios.post(`${url}/sendforgot_user_otp`, {
            email
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const deleteShopFromWishList = async (shopId: any, token: any) => {
    try {
        const data: any = await axios.delete(`${url}/wish-list-remove-shop`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: {
                shop_id: shopId
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}


export const addProductToWishList = async (product_id: any, token: any) => {
    try {
        const data: any = await axios.post(`${url}/wish-list-add`, {
            product_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}


export const savePaymentDetails = async (body: any, token: any) => {
    try {
        const data: any = await axios.post(`${url}/payment/save`, {
            ...body
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getAdminPaymentList = async (token: any,filter:string) => {
    try {
        const data: any = await axios.get(`${url}/admin/payment/details?${filter}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getCustomerPaymentList = async (token: any,filter:string) => {
    try {
        const data: any = await axios.get(`${url}/customer/payment/details?${filter}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getCustomerCurrentPlan = async (token: any) => {
    try {
        const data: any = await axios.get(`${url}/customer/payment/latest`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getProductWishList = async (token: any) => {
    try {
        const data: any = await axios.get(`${url}/wish-list`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const deleteProductFromWishList = async (product_id: any, token: any) => {
    try {
        const data: any = await axios.delete(`${url}/wish-list-remove`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: {
                product_id: product_id
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}



export const getUserData = async (userId: any, token: String) => {
    try {
        const data: any = await axios.get(`${url}/user_details/${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const getPublicShopDetails = async (shopId: any) => {
    try {
        const data: any = await axios.get(`${url}/shop_details_open/${shopId}`);
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}



export const updateUserData = async (userId: any, body: any, token: String) => {
    try {
        const data: any = await axios.post(`${url}/profile_update/${userId}`, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const sendBulkMail = async ( body: any, token: String) => {
    try {
        const data: any = await axios.post(`${url}/bulk_email_send`, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e: any) {
        throw new Error(e);
    }
}

export const deleteProductByCustomer = async (id: any, token: String) => {
    try {
        const data: any = await axios.delete(`${url}/product_delete/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return data;
    }
    catch (e) {
        console.log(`Error : ${e}`)
    }
}

export const verifyOTP = async (otp: String, email: String) => {
    try {
        const data: any = await axios.post(`${url}/users_otp_check`, {
            email,
            otp
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return data;
    }
    catch (e: any) {
        console.log(`Error : ${e}`);
        throw new Error(e)
    }
}


