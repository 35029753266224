import { createContext, useEffect, useState } from 'react';


const AuthContext = createContext({});



const AuthContextWrapper = ({ children }: any) => {
    const [isAuth] = useState<boolean>(localStorage.getItem('token') !== null);
    const contextValues = {
    };

    useEffect(() => {
        if(!isAuth) 
            {
                localStorage.removeItem('token');
            }
    }, [isAuth])
    return (
        <AuthContext.Provider value={contextValues}>
            {children}
        </AuthContext.Provider>
    );
};
export { AuthContextWrapper, AuthContext };
