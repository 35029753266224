import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { Route, Routes } from "react-router";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import routes from "../routes";
import { AuthContextWrapper } from "../services/AuthContext";


function MainLayout(props: any) {

  const loading = () => <div className="flex h-screen w-screen items-center justify-center ">

    <div className="flex items-center font-bold text-2xl text-slate-600">  <ProgressSpinner className="w-12" /></div>
  </div>

  return (
    <AuthContextWrapper>
      <div className="scroll-smooth" >
        <Header />
        <div className="flex h-[96px] w-full"> </div>

        <Suspense fallback={loading()}>
          <Routes>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <route.component {...props} />
                  } />
              ) : (null);
            })}
          </Routes>
        </Suspense>
        <Footer />

      </div>
    </AuthContextWrapper>
  );
}

export default MainLayout;
