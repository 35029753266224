

function Footer() {

  return (
    <div className="">
        
    </div>
  );
}

export default Footer;
