import { Fragment, useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import {
  DocumentTextIcon,
  LockClosedIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import LocationSelectComponent from "../components/LocationSelectComponent";
import {
  LocationMarkerIcon,
  SearchIcon,
  UserIcon,
  BookmarkIcon,
  HomeIcon,
  XIcon,
  MenuIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { useLocation, useNavigate } from "react-router";
import { useAtom } from "jotai";
import {
  locationModalShowAtom,
  locationStorageAtom,
  tokenStorageAtom,
  userStorageAtom,
} from "../services/jotaiService";
import { Disclosure, Transition } from "@headlessui/react";
import { ShoppingBagIcon } from "@heroicons/react/solid";
import Login from "../pages/Login";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

function Header() {
  const toast = useRef<any>(null);
  const [userAtom, setUserAtom] = useAtom(userStorageAtom);
  const [tokenAtom, setTokenAtom] = useAtom(tokenStorageAtom);
  const [locationAtom] = useAtom(locationStorageAtom);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showLeftSideDrawer, setShowLeftSideDrawer] = useAtom(
    locationModalShowAtom
  );
  const [isScrolled, setIsScrolled] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [selectedPath, setSelectedPath] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const logoutHandler = () => {
    confirmDialog({
      message: `Log out from farebiss?`,
      header: null,
      icon: null,
      className:"w-[22rem]",
      contentClassName: "text-xl font-semibold",
      acceptLabel: "Yes, log out",
      rejectLabel: "cancel",
      accept: () => {
        setUserAtom({});
        setTokenAtom("");
        navigate("/guest");
      },
      reject: () => {},
    });
  };

  const navigateToHome = () => {
    switch (Number(userAtom?.type)) {
      case 0:
        return navigate("/admin");
      case 1:
        return navigate("/associate");
      case 2:
        return navigate("/customer");
      case 3:
        return navigate("/");
      default:
        return navigate("/guest");
    }
  };

  const pathCheckWithRole = (path: string) => {
    switch (Number(userAtom?.type)) {
      //
      case 0:
        if (!adminNav?.some((item: any) => path.includes(item.to)))
          return navigateToHome();
        break;
      case 1:
        if (!associateNav?.some((item: any) => path.includes(item.to)))
          return navigateToHome();
        break;
      case 2:
        if (!customerNav?.some((item: any) => path.includes(item.to)))
          return navigateToHome();
        break;
      case 3:
        if (!userNav?.some((item: any) => path.includes(item.to)))
          return navigateToHome();
        break;
      default:
        if (!guestNav?.some((item: any) => path.includes(item.to)))
          return navigateToHome();
        break;
    }
  };

  const setHeaderDatas = () => {
    if (locationAtom.place_name) {
      const locationName =
        locationAtom?.place_name.length > 20
          ? `${locationAtom.place_name.slice(0, 20)}...`
          : locationAtom.place_name;
      setLocationName(locationName);
    }
  };
  const userNav: any = [
    {
      label: "Home",
      to: "/",
      icon: <HomeIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "Search",
      to: "/search",
      icon: <SearchIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "Wish List",
      to: "/wishlist",
      icon: <BookmarkIcon className="w-4 mr-1" />,
      showInHeader: true,
    },
    {
      label: userAtom?.name,
      to: "/profile",
      icon: <UserIcon className="w-4 mr-1" />,
      showInHeader: true,
    },
  ];

  const customerNav: any = [
    {
      label: "Dashboard",
      to: "/customer",
      icon: <HomeIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "Customer Business",
      to: "/customer/business/",
      icon: <ShoppingBagIcon className="w-4 mr-2" />,
      showInHeader: false,
    },
    {
      label: "Billings",
      to: "/customer/transactions",
      icon: <DocumentTextIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: userAtom?.name,
      to: "/profile",
      icon: <UserIcon className="w-4 mr-1" />,
      showInHeader: true,
    },

    {
      label: userAtom?.name,
      to: "/showqr",
      icon: null,
      showInHeader: false,
    },
  ];

  const guestNav: any = [
    {
      label: "Home",
      to: "/guest",
      icon: <HomeIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "Home",
      to: "/store/",
      icon: null,
      showInHeader: false,
    },
    {
      label: "Search",
      to: "/search",
      icon: <SearchIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "Join With Us",
      to: "/joinwithus",
      icon: null,
      showInHeader: false,
    },
    {
      label: "Signup",
      to: "/signup",
      icon: null,
      showInHeader: false,
    },
    {
      label: "Signup Career",
      to: "/signupcareer",
      icon: null,
      showInHeader: false,
    },
    {
      label: "Signup Business",
      to: "/signupbusiness",
      icon: null,
      showInHeader: false,
    },
    {
      label: "Reset Password",
      to: "/resetpassword",
      icon: null,
      showInHeader: false,
    },
  ];

  const associateNav: any = [
    {
      label: "Home",
      to: "/associate",
      icon: <HomeIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "User List",
      to: "/admin/userlist",
      icon: null,
      showInHeader: false,
    },
    {
      label: "Customers",
      to: "/associate/customerlist",
      icon: <ShoppingBagIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "Transactions",
      to: "/associate/transactions",
      icon: <DocumentTextIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: userAtom?.name,
      to: "/profile",
      icon: <UserIcon className="w-4 mr-1" />,
      showInHeader: true,
    },
  ];

  const adminNav: any = [
    {
      label: "Home",
      to: "/admin",
      icon: <HomeIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "Home",
      to: "/admin/userlist",
      icon: <HomeIcon className="w-4 mr-2" />,
      showInHeader: false,
    },
    {
      label: "Customers",
      to: "/admin/customerlist",
      icon: <ShoppingBagIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "",
      to: "/admin/associate/",
      icon: null,
      showInHeader: false,
    },
    // {
    //   label: 'Associates',
    //   to: '/admin/associates',
    //   icon: <UserGroupIcon className="w-4 mr-2" />,
    //   showInHeader: true
    // },

    {
      label: "Shops",
      to: "/admin/businesslist",
      icon: <UserGroupIcon className="w-4 mr-2" />,
      showInHeader: true,
    },
    {
      label: "Transactions",
      to: "/admin/transactions",
      icon: <DocumentTextIcon className="w-4 mr-2" />,
      showInHeader: false,
    },

    {
      label: userAtom?.name,
      to: "/profile",
      icon: <UserIcon className="w-4 mr-1" />,
      showInHeader: true,
    },
    //   , {
    //   label: 'Wish List',
    //   to: '/wishlist',
    //   icon: <BookmarkIcon className="w-4 mr-1" />
    // }
  ];

  const navItems = (): any => {
    switch (Number(userAtom?.type)) {
      case 0:
        return adminNav?.filter((item: any) => item.showInHeader);
      case 1:
        return associateNav?.filter((item: any) => item.showInHeader);
      case 2:
        return customerNav?.filter((item: any) => item.showInHeader);
      case 3:
        return userNav?.filter((item: any) => item.showInHeader);
      default:
        return guestNav?.filter((item: any) => item.showInHeader);
    }
  };

  const setUpData = () => {
    setHeaderDatas();
    window.onscroll = function () {
      setIsScrolled(document.documentElement.scrollTop > 0);
    };
  };

  useEffect(() => {
    setUpData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedPath(location.pathname);
    pathCheckWithRole(location.pathname);
  }, [location.pathname, userAtom]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (locationAtom.place_name) {
      const locationName =
        locationAtom?.place_name.length > 20
          ? `${locationAtom.place_name.slice(0, 20)}...`
          : locationAtom.place_name;
      setLocationName(locationName);
    }
  }, [locationAtom]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="backdrop-blur-sm  z-10 fixed w-full border">
      <Toast ref={toast} />
      <ConfirmDialog />
      <Sidebar
        visible={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        position="right"
        className="!w-full md:!w-[400px] "
      >
        <Login onHide={() => setShowLoginModal(false)} />
      </Sidebar>
      {}
      <Sidebar
        visible={showLeftSideDrawer}
        onHide={() => setShowLeftSideDrawer(false)}
      >
        <LocationSelectComponent
          locationName={locationName}
          setLocationName={setLocationName}
          closeModal={() => setShowLeftSideDrawer(false)}
          toast={toast}
        />
      </Sidebar>

      <div className="lg:hidden   w-full flex items-center border">
        <Disclosure as="nav" className=" w-full   rounded-lg border-none ">
          {({ open, close }) => (
            <>
              <div
                className={`border flex p-2 justify-between items-center bg-white`}
              >
                <div className=" flex  justify-left items-center px-2 sm:px-8 lg:min-w-[30%]">
                  <img
                    onClick={navigateToHome}
                    alt="header"
                    src="/assets/icons/farebizz_header_logo.svg"
                    className=" w-[30px] h-[50px] cursor-pointer"
                  />
                  {(Number(userAtom?.type) === 3 || tokenAtom === "") && (
                    <div className="flex cursor-pointer">
                      <div
                        className={`flex p-2 text-gray-500 hover:text-[#0F00FF] text-sm sm:text-base`}
                        onClick={() => setShowLeftSideDrawer(true)}
                      >
                        <LocationMarkerIcon className="w-4 mr-0.5  " />{" "}
                        {locationName}{" "}
                        <ChevronDownIcon className="w-6 mt-0.5 ml-0.5  " />
                      </div>
                    </div>
                  )}
                </div>
                <Disclosure.Button className="bg-gray-700 inline-flex items-center justify-center h-[35px]  p-2 rounded-md text-gray-400 hover:text-white  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="bg-none h-screen" onClick={() => close()}>
                  <Disclosure.Panel
                    className={`gap-1 bg-white z-10 transition  opacity-100 h-full `}
                  >
                    <div className={``}>
                      {navItems().map((item: any, index: number) => (
                        <div
                          key={`mob-nav-item-${index}`}
                          onClick={() => {
                            navigate(item.to);
                          }}
                          className={` p-4 border-2 border-gray-300 hover:text-[#0F00FF] hover:bg-gray-200  text-gray-700 font-bold flex cursor-pointer ${
                            selectedPath === item.to
                              ? "bg-gray-200 !text-[#0F00FF]"
                              : ""
                          }`}
                        >
                          {item.icon && item.icon} {item.label || ""}
                        </div>
                      ))}
                    </div>

                    {tokenAtom === "" ? (
                      <div
                        onClick={() => setShowLoginModal(true)}
                        className={` p-4 border-2 border-gray-300 text-cyan-800 hover:bg-gray-200  font-bold flex cursor-pointer`}
                      >
                        <LockClosedIcon className="w-4 mr-2" /> Login
                      </div>
                    ) : (
                      <div
                        onClick={logoutHandler}
                        className={` p-4 border-2 border-gray-300 text-[#ff8731] hover:bg-gray-200  font-bold flex cursor-pointer`}
                      >
                        Logout
                      </div>
                    )}
                  </Disclosure.Panel>
                </div>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>

      <div
        className={`lg:flex hidden lg:h-[96px] bg-[rgb(255,255,255)] lg:justify-between shadow-md  ${
          isScrolled ? "opacity-90" : ""
        } `}
      >
        <div className=" flex  justify-left items-start lg:items-center px-8 lg:min-w-[30%]">
          <img
            onClick={navigateToHome}
            alt="link"
            src="/assets/icons/farebizz_header_logo.svg"
            className=" w-[50px] h-[70px] cursor-pointer"
          />
          {(Number(userAtom?.type) === 3 ||
            tokenAtom === "" ||
            tokenAtom === "") && (
            <div className="flex cursor-pointer">
              <div
                className={`flex p-2 text-gray-500 hover:text-[#0F00FF] `}
                onClick={() => setShowLeftSideDrawer(true)}
              >
                <LocationMarkerIcon className="w-4 mr-0.5  " /> {locationName}{" "}
                <ChevronDownIcon className="w-6 mt-0.5 ml-0.5  " />
              </div>
            </div>
          )}
        </div>

        <div className=" lg:flex items-center pr-8 hidden">
          <ul className="flex">
            {navItems().map((item: any, index: number) => (
              <li
                key={`headir-full-nav-${index}`}
                onClick={() => {
                  navigate(item.to);
                }}
                className={`${
                  selectedPath === item.to ? "text-[#0F00FF]" : ""
                } font-bold flex  text-gray-500 hover:text-[#5349EA] cursor-pointer items-center p-4 xl:p-8 text-[16px]`}
              >
                {item.icon && item.icon} {item.label || ""}
              </li>
            ))}
            {tokenAtom === "" && (
              <li className="flex items-center p-4 xl:p-8">
                <div
                  onClick={() => {
                    navigate("/joinwithus");
                  }}
                  className="rounded-xl bg-sky-700 hover:bg-sky-800 text-sm p-1 px-2.5 font-bold text-neutral-50 cursor-pointer"
                >
                  Join with us ?
                </div>
              </li>
            )}
            {tokenAtom === "" ? (
              <li
                onClick={() => setShowLoginModal(true)}
                className="font-bold flex  hover:text-cyan-900 text-cyan-800 cursor-pointer items-center p-4 xl:p-8 text-[16px]"
              >
                <LockClosedIcon className="w-4 mr-2" /> Login
              </li>
            ) : (
              <li
                onClick={logoutHandler}
                className="font-bold text-[#ff8731] hover:text-[#ff8731] cursor-pointer items-center p-4 xl:p-8 text-[16px]"
              >
                Logout
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
