import { QueryClient as ReactQueryClient, QueryClientProvider as ReactQueryClientProvider  } from "react-query";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';

import MainLayout from './pages/MainLayout';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  const reactQueryClient = new ReactQueryClient()

  return (
    <div className="App">
           <ReactQueryClientProvider client={reactQueryClient}>

      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainLayout />} >
            <Route path="*" element={<MainLayout />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter></ReactQueryClientProvider>
    </div>
  );
}

export default App;
